<template>
  <v-container
    fluid
  >
    <div
      v-if="showTooltip"
      ref="svgTooltip"
      class="d-flex justify-center align-center pa-2"
      :style="`position: fixed; top: ${tooltipY}px; left: ${tooltipX}px;
            min-width: 100px; min-height: 50px; background: white; border-radius: 15px;
            opacity: .9; z-index: 5;
          `"
    >
      <span>
        {{ tooltipText }}
      </span>
    </div>
    <v-row>
      <v-col
        class="pa-0"
        cols="12"
      >
        <v-container
          fluid
          class="mb-0 py-0 px-6"
        >
          <v-card
            style="width: 100%; height: 100%;"
          >
            <ewc-toolbar>
              <template v-slot:siteSelection>
                <v-col
                  cols="4"
                  md="3"
                >
                  <v-select
                    id="ewcSiteSelectionDropdown"
                    v-model="selectedSite"
                    :items="sites"
                    label="Select Site"
                    item-text="siteName"
                    :loading="loadingSites"
                    return-object
                    class="mt-7"
                    outlined
                    dense
                    flat
                    @change=" (selection) => {
                      showAlert = false
                      store.set('app/disableEwc', false)
                      grids = selection.siteGrids
                      if(grids.length === 1){
                        selectedFloor = grids[0]
                        onSelectFloor(selectedFloor)
                      } else {
                        selectedFloor = ''
                        if(grids.length === 0){
                          showAlert = true
                          alertType = 'info'
                          alertMessage = aletMessageSiteNotSetUp
                          store.set('app/disableEwc', true)
                        }
                      }
                    }
                    "
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="3"
                >
                  <v-select
                    id="ewcFloorplanSelectionDropdown"
                    v-model="selectedFloor"
                    :disabled="loadingFloorplan || !selectedSite"
                    :loading="loadingFloorplan"
                    :items="grids"
                    label="Select Floorplan"
                    item-text="name"
                    return-object
                    class="mt-7"
                    outlined
                    dense
                    flat
                    @change=" (selection) => onSelectFloor(selection)"
                  />
                </v-col>
              </template>
              <template v-slot:pageSpecificIcons>
                <v-btn
                  color="primary"
                  class="font-weight-bold"
                  :text="!powerView"
                  :disabled="!selectedFloor"
                  @click="powerView = true"
                >
                  Power
                </v-btn>
                <v-btn
                  color="primary"
                  class="font-weight-bold mr-2"
                  :text="powerView"
                  :disabled="!selectedFloor"
                  @click="powerView = false"
                >
                  Network
                </v-btn>
              </template>
            </ewc-toolbar>
          </v-card>
        </v-container>
      </v-col>
      <v-col
        v-if="showAlert"
        class="px-6 pb-0"
        cols="12"
      >
        <v-alert
          :type="alertType"
          class="mb-0"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="selectedFloor">
      <v-col
        v-show="powerView"
        cols="12"
        lg="8"
        style="position: relative;"
      >
        <div
          style="height: 800px; width: 100%;"
        >
          <grid-wrapper
            :redraw-grid="powerView.toString() + loadingPowerMap.toString()"
            :floorplan="floorplan"
            :loading-floorplan="loadingFloorplan || loadingPowerMap"
            :local-bus="localBus"
            :selected-power-equipment="selectedPowerEquipment"
          />
        </div>
      </v-col>
      <ewc-network-main-container
        v-show="!powerView"
        :grid-id="selectedFloor.id"
        :local-bus="localBus"
        :floorplan="floorplan"
      />
      <v-col
        v-show="selectedFloor && powerView && showPowerInfoBox"
        :class="`${$vuetify.breakpoint.lgAndUp ? 'pl-0 py-6' : 'pl-6 py-0'} pr-6`"
        cols="12"
        lg="4"
      >
        <v-row>
          <v-col
            class="pa-0"
            cols="12"
          >
            <div :style="`height: ${800}; width: 100%;`">
              <ewc-power-info-box
                :loading="loadingPowerMap || loadingFloorplan"
                :power-equipment-data="powerEquipmentData"
                :local-bus="localBus"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-show="selectedFloor && powerView && !showPowerInfoBox"
        :class="`${$vuetify.breakpoint.lgAndUp ? 'pl-0 py-6' : 'pl-6 py-0'} pr-6`"
        cols="12"
        lg="4"
      >
        <v-row>
          <v-col
            class="pa-0"
            cols="12"
          >
            <div :style="`height: ${600}px; width: 100%;`">
              <ewc-information-card
                :local-bus="localBus"
                :power-left="selectedFloorItemLeftPowerStrip"
                :power-right="selectedFloorItemRightPowerStrip"
              />
            </div>
          </v-col>
          <v-col
            class="pa-0"
            cols="12"
          >
            <div
              :style="`height: ${200}; width: 100%;`"
            >
              <ewc-extra-power-details-box
                :local-bus="localBus"
                :power-load="extraDetailsPowerLoad"
                :power-source="extraDetailsPowerSource"
                :display-state="extraDetailsViewState"
                :disable-add-power-strip="selectedFloorItemLeftPowerStrip !== null && selectedFloorItemRightPowerStrip !== null"
                @open-add-power-strip="showAddPowerStripDialog = true"
                @open-edit-power-strip="
                  statePowerStripLocation = selectedPowerStrip.position === 'LEFT' ? 'Left' : 'Right'
                  statePowerStripPowerParent = extraDetailsPowerSource
                  editPowerStrip = true
                  showAddPowerStripDialog = true
                "
                @open-remove-power-strip="showDeletePowerStripDialog = true"
                @open-rack-item-power-setup="openPowerSetupDialog"
                @open-rack-item-model-setup="openModelSetupDialog"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showAddPowerStripDialog"
      width="600px"
      persistent
    >
      <v-card
        class="pa-6"
        height="40vh"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          {{ editPowerStrip ? 'Edit Power Strip' : 'Add Power Strip' }}
        </v-card-title>
        <v-sheet class="mx-8">
          <v-expand-transition>
            <div
              class="my-6"
            >
              <div>
                Select Location For The Power Strip:
              </div>
              <v-select
                v-model="statePowerStripLocation"
                :items="powerStripLocationOptions"
                dense
              />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div
              v-if="statePowerStripLocation !== ''"
              class="my-6"
            >
              <div>
                Select What This Power Strip Will Be Powered By:
              </div>
              <v-select
                v-model="statePowerStripPowerParent"
                :items="powerStripParentOptions"
                return-object
                item-text="name"
                dense
              />
            </div>
          </v-expand-transition>
        </v-sheet>
        <v-card-actions class="justify-center pb-5">
          <v-btn
            :disabled="statePowerStripPowerParent === ''"
            :loading="addingPowerStrip"
            color="green darken-1"
            text
            class="font-weight-bold"
            width="100px"
            height="56px"
            style="font-size: 14px"
            @click="editPowerStrip ? updatePowerStrip() : addPowerStrip() "
          >
            {{ editPowerStrip ? 'Edit' : 'Add' }}
          </v-btn>
          <v-btn
            :disabled="addingPowerStrip"
            color="gray lighten-1"
            text
            class="font-weight-bold"
            width="100px"
            height="56px"
            style="font-size: 14px"
            @click="
              statePowerStripLocation = ''
              statePowerStripPowerParent = ''
              showAddPowerStripAlert = false
              showAddPowerStripDialog = false
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-alert
        v-if="showAddPowerStripAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ addPowerStripAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showDeletePowerStripDialog"
      max-width="750"
      persistent
    >
      <v-card
        class="pa-4 px-4"
      >
        <v-card-title class="text-h5 mb-3 justify-center">
          Are you sure you want to delete this power strip? <span> Any connected rack equipment will have this power source removed.</span>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="red darken-1"
            text
            class="font-weight-bold"
            :loading="deletingPowerStrip"
            @click="deletePowerStrip"
          >
            Delete
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            class="font-weight-bold"
            :disabled="deletingPowerStrip"
            @click="
              showDeletePowerStripDialog = false
              showDeletePowerStripAlert = false
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-alert
        v-if="showDeletePowerStripAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ deletePowerStripAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showRackItemPowerSetupDialog"
      width="600px"
      persistent
    >
      <v-card
        class="pa-6"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Equipment Power Setup
        </v-card-title>
        <v-sheet
          v-if="selectedRackItem"
          class="mx-8"
        >
          <div class="font-weight-bold mb-2">
            {{ selectedRackItem.equipmentModel.name }}
          </div>
          <v-row>
            <v-col cols="2 justify-center">
              <v-img
                v-if="selectedFloorItemLeftPowerStrip"
                src="@/assets/power_strip.png"
                height="250"
                contain
                width="40"
                class="ml-4"
                :gradient="leftPowerStripHighlighted ? 'to top right, rgba(109, 224, 237, .3), rgba(109, 224, 237, .3)' : ''"
              />
            </v-col>
            <v-col
              cols="8"
              class="d-flex align-center justify-center"
            >
              <v-img
                v-if="selectedRackItem.equipmentModel.imagePath"
                :src="selectedRackItem.equipmentModel.imagePath"
                max-width="300"
                max-height="300"
                contain
              />
              <div
                v-else
                style="width: 250px; height: 250px;"
                class="d-flex justify-center align-center"
              >
                <v-icon x-large>
                  {{ mdiImageOff }}
                </v-icon>
              </div>
            </v-col>
            <v-col cols="2">
              <v-img
                v-if="selectedFloorItemRightPowerStrip"
                src="@/assets/power_strip.png"
                height="250"
                contain
                width="40"
                :gradient="rightPowerStripHighlighted ? 'to top right, rgba(109, 224, 237, .3), rgba(109, 224, 237, .3)' : ''"
              />
            </v-col>
          </v-row>
          <div class="mt-2">
            Maximum Power Draw:  <span class="font-weight-bold"> {{ selectedRackItemPowerDraw }} W</span>
          </div>
          <div class="d-flex flex-wrap mt-4">
            <template v-for="plug, index in powerPlugs">
              <v-expand-transition :key="index">
                <div
                  class="d-flex align-center mr-2"
                >
                  <span class="mb-5">Plug #{{ index + 1 }}: </span>
                  <div style="width: 150px;">
                    <v-select
                      v-model="powerPlugSelectionArray[index]"
                      class="ml-4"
                      :items="['Left', 'Right']"
                      filled
                      dense
                      @click="addHighlight"
                    />
                  </div>
                </div>
              </v-expand-transition>
            </template>
          </div>
        </v-sheet>
        <v-card-actions class="justify-center pb-5">
          <v-btn
            :disabled="false"
            :loading="updatingPowerStripToRackConnections"
            color="green darken-1"
            text
            class="font-weight-bold"
            width="100px"
            height="56px"
            style="font-size: 14px"
            @click="updatePowerSetup"
          >
            Update
          </v-btn>
          <v-btn
            :disabled="updatingPowerStripToRackConnections"
            color="gray lighten-1"
            text
            class="font-weight-bold"
            width="100px"
            height="56px"
            style="font-size: 14px"
            @click="
              showRackItemPowerSetupDialog = false
              showUpdatePowerSetupAlert = false
              powerPlugs = []
              powerPlugSelectionArray = []
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-alert
        v-if="showUpdatePowerSetupAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ updatePowerSetupAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showNoModelPowerInformationDialog"
      width="600px"
    >
      <v-card
        class="pa-6"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Equipment Power Setup
        </v-card-title>
        <v-sheet
          v-if="selectedRackItem"
          class="mx-8"
        >
          There is currently no power information associated with this equipment model. Please add this information in order to complete power setup.
        </v-sheet>
        <v-card-actions class="justify-center pb-5">
          <v-btn
            text
            color="blue darken-1"
            class="font-weight-bold"
            @click="
              showNoModelPowerInformationDialog = false
              showRackItemModelSetupDialog = true
            "
          >
            Add Model Power Information
          </v-btn>
          <v-btn
            color="gray lighten-1"
            text
            class="font-weight-bold"
            @click="
              showNoModelPowerInformationDialog = false
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showRackItemModelSetupDialog"
      width="600px"
      persistent
    >
      <v-card
        class="pa-6"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Equipment Model Information Setup
        </v-card-title>
        <v-sheet class="mx-8 mt-4">
          <template v-if="selectedRackItem">
            <div class="font-weight-bold">
              {{ selectedRackItem.equipmentModel.name }}:
            </div>
            <v-img
              v-if="selectedRackItem.equipmentModel.imagePath"
              :src="selectedRackItem.equipmentModel.imagePath"
              max-width="450"
              max-height="450"
              contain
            />
            <div
              v-else
              style="width: 200px; height: 200px;"
              class="d-flex justify-center align-center"
            >
              <v-icon x-large>
                {{ mdiImageOff }}
              </v-icon>
            </div>
          </template>
          <v-form v-model="isModelPowerInformationFormValid">
            <div
              class="d-flex align-center mt-6"
            >
              <span>Number of power inputs: </span>
              <div style="width: 100px;">
                <v-select
                  v-model="stateRackItemPowerInputs"
                  class="ml-6"
                  :items="[1, 2, 3, 4, 5, 6, 7, 8]"
                  dense
                />
              </div>
            </div>
            <div
              class="d-flex align-center"
            >
              <span>Total power draw: </span>
              <div style="width: 100px;">
                <v-text-field
                  v-model="stateRackItemPowerDraw"
                  class="ml-6"
                  dense
                  type="number"
                  :rules="[rules.positiveNumber, rules.wholeNumber]"
                />
              </div>
              <span class="ml-1">W</span>
            </div>
          </v-form>
        </v-sheet>
        <v-card-actions class="justify-center pb-5">
          <v-btn
            :disabled="!isModelPowerInformationFormValid"
            :loading="updatingModelSetup"
            color="green darken-1"
            text
            class="font-weight-bold"
            width="100px"
            height="56px"
            style="font-size: 14px"
            @click="updateModelSetup"
          >
            Update
          </v-btn>
          <v-btn
            :disabled="updatingModelSetup"
            color="gray lighten-1"
            text
            class="font-weight-bold"
            width="100px"
            height="56px"
            style="font-size: 14px"
            @click="
              showRackItemModelSetupDialog = false
              stateRackItemPowerInputs = -1
              stateRackItemPowerDraw = ''
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-alert
        v-if="showUpdateModelSetupAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ updateModelSetupAlertMessage }}
      </v-alert>
    </v-dialog>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      timeout="3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import ewcApi from '@/api/ewc.js'
  import common from '@/api/common.js'
  import ewcHolder from '@/util/ewc-holder'
  import equipmentApi from '@/api/equipment'
  import store from '@/store'
  import { mdiPlus, mdiClose, mdiImageOff } from '@mdi/js'
  import { binarySearchArrayOfObjects } from '@/util/helpers'

  export default {
    name: 'EWCPowerAndNetwork',
    data: () => ({
      localBus: new Vue(),
      sites: [],
      grids: [],
      selectedSite: '',
      selectedFloor: '',
      loadingSites: false,

      loadingPowerMap: false,

      floorplan: {},
      loadingFloorplan: false,

      showAlert: false,
      alertType: 'error',
      alertMessage: '',
      alertMessageDataCenters: 'There was an error loading Data Centers. Please try again later.',
      alertMessageFloorplan: 'There was an error loading Floorplan. Please try again later.',
      alertMessageEquipmentTable: 'There was an error loading Equipment Table. Please try again later.',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',
      alertMessageNoFloorplans: 'Your Sites are currently not set up with Floorplans for use with EWC. Please contact us if you would like more information about this feature.',
      aletMessageSiteNotSetUp: 'This site is currently not set up with Floorplans for use with EWC. Please contact us if you are interested in adding this site to EWC.',

      showSnackBar: false,
      snackBarMessage: '',
      snackBarMessageEditPowerStripSuccess: 'Successfully updated power strip!',
      snackBarMessageDeletePowerStripSuccess: 'Successfully removed power strip!',
      snackBarMessageAddPowerStripSuccess: 'Successfully added a power strip to equipment!',
      snackBarMessageUpdateModelPowerInformationSuccess: 'Successfully updated model power information!',
      snackBarMessageUpdatePowerStripConnectionsSuccess: 'Successfully updated rack equipment power strip connections!',
      snackBarMessageAddNetworkEquipment: 'Successfully added network equipment!',
      snackBarMessageUpdateNetworkModel: 'Successfully updated network model information!',
      snackBarMessageRemoveNetworkEquipment: 'Successfully removed equipment from network map!',

      powerView: true,

      showTooltip: false,
      tooltipY: 0,
      tooltipX: 0,
      tooltipText: '',

      ewcHolder: ewcHolder,

      tooltips: false,

      store: store,

      powerEquipmentData: null,
      selectedPowerEquipment: [],
      showPowerInfoBox: true,

      selectedFloorItem: null,
      selectedFloorItemLeftPowerStrip: null,
      selectedFloorItemRightPowerStrip: null,
      selectedRackItem: null,

      extraDetailsPowerSource: '',
      extraDetailsPowerLoad: 0,
      extraDetailsViewState: 'default',

      showAddPowerStripDialog: false,
      statePowerStripLocation: '',
      statePowerStripPowerParent: '',

      selectedPowerStrip: null,

      showAddPowerStripAlert: false,
      addingPowerStrip: false,
      addPowerStripAlertMessage: '',

      editPowerStrip: false,

      showDeletePowerStripDialog: false,
      deletingPowerStrip: false,
      showDeletePowerStripAlert: false,
      deletePowerStripAlertMessage: '',

      showRackItemPowerSetupDialog: false,
      updatingPowerStripToRackConnections: false,
      showUpdatePowerSetupAlert: false,
      updatePowerSetupAlertMessage: '',
      powerPlugs: [],
      selectedRackItemPowerDraw: -1,
      powerPlugSelectionArray: [],
      rightPowerStripHighlighted: false,
      leftPowerStripHighlighted: false,
      showNoModelPowerInformationDialog: false,

      showRackItemModelSetupDialog: false,
      updatingModelSetup: false,
      isModelPowerInformationFormValid: false,
      showUpdateModelSetupAlert: false,
      updateModelSetupAlertMessage: '',
      stateRackItemPowerInputs: -1,
      stateRackItemPowerDraw: '',

      mdiPlus: mdiPlus,
      mdiClose: mdiClose,
      mdiImageOff: mdiImageOff,

      modelPowerInformation: [],

    }),
    computed: {
      powerStripLocationOptions () {
        const options = []
        if (!this.selectedFloorItemLeftPowerStrip) options.push('Left')
        if (!this.selectedFloorItemRightPowerStrip) options.push('Right')
        if (this.editPowerStrip) options.push(this.selectedPowerStrip.position === 'LEFT' ? 'Left' : 'Right')
        return options
      },
      powerStripParentOptions () {
        const parentOptions = this.findPowerStripParents(this.powerEquipmentData.powerEquipmentEwcList[0])
        return parentOptions
      },
      rules () {
        const rules = {}
        rules.positiveNumber = (value) => value > 0 || 'Please enter a positive number'
        rules.wholeNumber = (value) => value % 1 === 0 || 'Please enter a whole number'
        return rules
      },
    },

    created () {
      this.getModelPowerInformation()

      ewcHolder.getDataCenters() ? this.sites = ewcHolder.getDataCenters() : this.getDataCentersForUser()
      if (ewcHolder.getLatestSiteAndFloorSelection()) {
        this.selectedSite = ewcHolder.getLatestSiteAndFloorSelection().site
        this.grids = this.selectedSite.siteGrids
        this.selectedFloor = ewcHolder.getLatestSiteAndFloorSelection().floor
      }

      if (this.selectedFloor) {
        this.getEquipmentEwcForSite(this.selectedFloor.id)
        this.getPowerMap()
      }

      this.localBus.$on('onSelectFloorItem', floorItem => {
        this.showPowerInfoBox = false
        this.selectedFloorItem = floorItem
        this.selectedPowerEquipment = []
        this.localBus.$emit('clearSelectedPowerStrip')

        this.setDefaultStateExtraPowerInformationBox(floorItem)
      })
      this.localBus.$on('clearSelectedFloorItem', () => {
        this.showPowerInfoBox = true
        this.localBus.$emit('clearSelectedPowerItem')
      })
      this.localBus.$on('onSelectRackItem', rackItem => {
        this.selectedRackItem = rackItem
        this.setExtraPowerInfoRackItem(rackItem)
      })
      this.localBus.$on('clearSelectedRackItem', () => {
        this.setDefaultStateExtraPowerInformationBox(this.selectedFloorItem)
      })
      this.localBus.$on('onSelectEnclosure', enclosure => {
        this.selectedRackItem = enclosure
        this.setExtraPowerInfoRackItem(enclosure)
      })
      this.localBus.$on('onSelectEnclosureItem', enclosureItem => {
      })
      this.localBus.$on('clearSelectedEnclosureItem', () => {
      })
      this.localBus.$on('selectPowerSource', (powerStripArray) => {
        this.selectPoweredEquipment(powerStripArray)
      })

      this.localBus.$on('selectPowerStrip', powerStrip => {
        this.localBus.$emit('clearSelectedRackItem')
        this.extraDetailsViewState = 'powerStrip'
        const powerSource = this.searchThroughPowerEquipment(this.powerEquipmentData.powerEquipmentEwcList[0], powerStrip.powerEquipmentEwcParentId)
        this.extraDetailsPowerLoad = powerStrip.totalLoad
        this.extraDetailsPowerSource = Object.keys(powerSource).length > 0 ? powerSource.name : 'None'
        this.selectedPowerStrip = powerStrip
      })
      this.localBus.$on('deselectPowerStrip', () => {
        this.setDefaultStateExtraPowerInformationBox(this.selectedFloorItem)
      })

      this.localBus.$on('display-tooltip', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = label
      })
      this.localBus.$on('hide-tooltip', () => {
        this.showTooltip = false
      })
    },

    methods: {
      getDataCentersForUser () {
        if (ewcHolder.getDataCenters()) {
          this.sites = ewcHolder.getDataCenters()
          if (this.sites.length === 1) {
            this.selectedSite = this.sites[0]
            this.grids = this.selectedSite.siteGrids
          }
          return
        }
        this.loadingSites = true
        ewcApi.getDataCentersForUser()
          .then(response => {
            console.log(response)
            this.sites = response.data
            if (this.checkForFloorPlans(this.sites)) {
              if (this.sites.length === 1) {
                this.selectedSite = this.sites[0]
                this.grids = this.selectedSite.siteGrids
              }
              store.set('app/disableEwc', false)
            } else {
              this.showAlert = true
              this.alertType = 'info'
              this.alertMessage = this.alertMessageNoFloorplans
              store.set('app/disableEwc', true)
            }
            this.loadingSites = false
            ewcHolder.setDataCenters(response.data)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getDataCentersForUser()
            } else {
              this.loadingSites = false
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageDataCenters
            }
          })
      },

      checkForFloorPlans (sites) {
        let floorplansPresent = false
        sites.forEach(site => {
          if (site.siteGrids.length > 0) {
            floorplansPresent = true
          }
        })
        return floorplansPresent
      },

      getEquipmentEwcForSite (gridId) {
        this.loadingFloorplan = true
        const floorplan = ewcHolder.getFloorplan()
        if (floorplan?.dataCenterGrid.id === parseInt(gridId)) {
          if (floorplan.activeEquipmentEwc.length > 100) {
            setTimeout(() => {
              this.loadingFloorplan = false
              this.$nextTick(() => { this.floorplan = floorplan })
            }, [0])
          } else {
            this.loadingFloorplan = false
            this.floorplan = floorplan
          }
        } else {
          equipmentApi.getEquipmentEwcForSite(gridId)
            .then((response) => {
              console.log(response)
              this.loadingFloorplan = false
              this.$nextTick(() => { this.floorplan = response.data })
              ewcHolder.setFloorplan(response.data)
            })
            .catch(async (error) => {
              console.log(error)
              if (await common.handleBadCall(error, this.$router) === true) {
                this.getEquipmentEwcForSite(gridId)
              } else {
                this.loadingFloorplan = false
                this.showAlert = true
                this.alertType = 'error'
                this.alertMessage = this.alertMessageFloorplan
                this.selectedFloor = ''
              }
            })
        }
      },

      onSelectFloor (selection) {
        this.showAlert = false
        this.localBus.$emit('clearSelectedItems')

        ewcHolder.setLatestSiteAndFloorSelection({ site: this.selectedSite, floor: this.selectedFloor })
        this.getEquipmentEwcForSite(selection.id)

        this.getPowerMap()
      },

      getPowerMap () {
        this.loadingPowerMap = true
        ewcApi.powerMapTest()
          .then((response) => {
            console.log(response)
            this.powerEquipmentData = response.data
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getPowerMap()
            } else {
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { this.loadingPowerMap = false })
      },
      getModelPowerInformation () {
        ewcApi.getModelPowerInformation()
          .then((response) => {
            console.log(response)
            this.modelPowerInformation = response.data
          })
          .catch((error) => {
            console.log(error)
            this.showAlert = true
            this.alertType = 'error'
            this.alertMessage = this.alertMessageGeneral
            common.handleBadCall(error, this.$router)
          })
      },

      selectPoweredEquipment (powerStripArray) {
        const poweredEquipmentArray = []
        powerStripArray.forEach(powerStrip => {
          if (!poweredEquipmentArray.includes(powerStrip.equipmentEwcParentId)) poweredEquipmentArray.push(powerStrip.equipmentEwcParentId)
        })
        this.selectedPowerEquipment = poweredEquipmentArray
      },

      checkForPowerStrips (floorItem) {
        const powerStripArray = []
        this.selectedFloorItemLeftPowerStrip = null
        this.selectedFloorItemRightPowerStrip = null
        this.powerEquipmentData.powerStripsEwc.forEach(powerStrip => {
          if (powerStrip.equipmentEwcParentId === floorItem.equipmentData.id) {
            if (powerStrip.position === 'LEFT') {
              this.selectedFloorItemLeftPowerStrip = powerStrip
              powerStripArray.push(powerStrip)
            } else if (powerStrip.position === 'RIGHT') {
              this.selectedFloorItemRightPowerStrip = powerStrip
              powerStripArray.push(powerStrip)
            }
          }
        })
        return powerStripArray
      },

      searchThroughPowerEquipment (powerEquipment, id) {
        if (powerEquipment.id === id) return powerEquipment
        let powerEquipmentMatch = {}
        for (let i = 0; i < powerEquipment.powerChildren.length; i++) {
          if (powerEquipment.powerChildren[i].id === id) {
            powerEquipmentMatch = powerEquipment.powerChildren[i]
            break
          } else {
            powerEquipmentMatch = this.searchThroughPowerEquipment(powerEquipment.powerChildren[i], id)
            if (powerEquipmentMatch.id === id) break
          }
        }

        return powerEquipmentMatch
      },
      findPowerStripParents (powerEquipment) {
        const parents = []
        if (powerEquipment.suppliesPowerToRack === 1) return powerEquipment
        for (let i = 0; i < powerEquipment.powerChildren.length; i++) {
          parents.push(this.findPowerStripParents(powerEquipment.powerChildren[i]))
        }

        return parents.flat()
      },

      setDefaultStateExtraPowerInformationBox (floorItem) {
        this.extraDetailsViewState = 'default'
        const powerStrips = this.checkForPowerStrips(floorItem)
        const poweredBy = []

        this.extraDetailsPowerLoad = 0
        powerStrips.forEach(powerStrip => {
          poweredBy.push(this.searchThroughPowerEquipment(this.powerEquipmentData.powerEquipmentEwcList[0], powerStrip.powerEquipmentEwcParentId))
          this.extraDetailsPowerLoad += powerStrip.totalLoad
        })

        this.extraDetailsPowerSource = ''
        poweredBy.forEach((powerSource, i) => {
          if (i > 0) this.extraDetailsPowerSource += ', '
          if (Object.keys(powerSource).length === 0) {
            this.extraDetailsPowerSource += 'None'
          } else {
            this.extraDetailsPowerSource += `${powerSource.name} `
          }
        })
      },

      setExtraPowerInfoRackItem (rackItem) {
        this.extraDetailsViewState = 'rackItem'
        this.localBus.$emit('clearSelectedPowerStrip')
        const connectedPowerStrip = this.powerEquipmentData.powerStripsEwc.forEach(powerStrip => {
          if (powerStrip.equipmentEwcParentId === rackItem.equipmentData.id) return powerStrip
        })
        if (!connectedPowerStrip) {
          this.extraDetailsPowerSource = 'None'
        } else {
          const powerSource = this.searchThroughPowerEquipment(this.powerEquipmentData.powerEquipmentEwcList[0], connectedPowerStrip.powerParent)
          this.extraDetailsPowerSource = Object.keys(powerSource).length > 0 ? powerSource.name : 'None'
        }
        // TODO add power load derived from rackItem equipment model data
        this.extraDetailsPowerLoad = 0
      },

      addPowerStrip () {
        this.addingPowerStrip = true
        ewcApi.addPowerStripEwc(
          {
            equipmentEwcParentId: this.selectedFloorItem.equipmentData.id,
            powerEquipmentEwcParentId: this.statePowerStripPowerParent.id,
            name: 'Power Strip',
            capacity: 0,
            position: this.statePowerStripLocation === 'Left' ? 'LEFT' : 'RIGHT',
          },
        )
          .then(response => {
            console.log(response)
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageAddPowerStripSuccess

            this.statePowerStripLocation = ''
            this.statePowerStripPowerParent = ''
            this.showAddPowerStripDialog = false
            this.selectedFloorItem = null
            this.selectedFloorItemLeftPowerStrip = null
            this.selectedFloorItemRightPowerStrip = null
            this.localBus.$emit('clearSelectedFloorItem')

            this.getPowerMap()
          })
          .catch(error => {
            console.log(error)
            this.showAddPowerStripAlert = true
            this.addPowerStripAlertMessage = this.alertMessageGeneral
            common.handleBadCall(error, this.$router)
          })
          .finally(() => { this.addingPowerStrip = false })
      },

      updatePowerStrip () {
        this.addingPowerStrip = true
        ewcApi.editPowerStripEwc(
          {
            powerStripId: this.selectedPowerStrip.id,
            powerEquipmentEwcParentId: this.statePowerStripPowerParent.id,
            name: 'Power Strip',
            capacity: 0,
            position: this.statePowerStripLocation === 'Left' ? 'LEFT' : 'RIGHT',
          },
        )
          .then(response => {
            console.log(response)
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageEditPowerStripSuccess

            this.statePowerStripLocation = ''
            this.statePowerStripPowerParent = ''
            this.showAddPowerStripDialog = false
            this.showAddPowerStripAlert = false
            this.selectedFloorItem = null
            this.selectedFloorItemLeftPowerStrip = null
            this.selectedFloorItemRightPowerStrip = null
            this.localBus.$emit('clearSelectedFloorItem')

            this.getPowerMap()
          })
          .catch(error => {
            console.log(error)
            this.showAddPowerStripAlert = true
            this.addPowerStripAlertMessage = this.alertMessageGeneral
            common.handleBadCall(error, this.$router)
          })
          .finally(() => { this.addingPowerStrip = false })
      },

      deletePowerStrip () {
        this.deletingPowerStrip = true
        ewcApi.deletePowerStripEwc(this.selectedPowerStrip.id)
          .then(response => {
            console.log(response)
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageDeletePowerStripSuccess
            this.showDeletePowerStripDialog = false
            this.showDeletePowerStripAlert = false
            this.selectedFloorItem = null
            this.selectedFloorItemLeftPowerStrip = null
            this.selectedFloorItemRightPowerStrip = null
            this.localBus.$emit('clearSelectedFloorItem')

            this.getPowerMap()
          })
          .catch(error => {
            console.log(error)
            this.showDeletePowerStripAlert = true
            this.deletePowerStripAlertMessage = this.alertMessageGeneral
            common.handleBadCall(error, this.$router)
          })
          .finally(() => { this.deletingPowerStrip = false })
      },

      openPowerSetupDialog () {
        const modelPowerInformationIndex = binarySearchArrayOfObjects(this.modelPowerInformation, 'equipmentModelId', 0, this.modelPowerInformation.length - 1, this.selectedRackItem.equipmentModel.id)
        if (modelPowerInformationIndex !== -1) {
          const plugArray = []
          for (let i = 0; i < this.modelPowerInformation[modelPowerInformationIndex].powerInputs; i++) {
            plugArray.push(i)
          }
          this.powerPlugs = [...plugArray]
          this.selectedRackItemPowerDraw = this.modelPowerInformation[modelPowerInformationIndex].load
          this.showRackItemPowerSetupDialog = true
          const powerStripToRackItemConnections = this.powerEquipmentData.powerStripToRackItemConnections[binarySearchArrayOfObjects(
            this.powerEquipmentData.powerStripToRackItemConnections,
            'equipmentId',
            0,
            this.powerEquipmentData.powerStripToRackItemConnections.length - 1,
            this.selectedRackItem.equipmentData.id,
          )].powerStripConnections
          const plugSelections = []
          powerStripToRackItemConnections.forEach(connection => {
            if (this.selectedFloorItemLeftPowerStrip.id === connection.powerStripId) {
              for (let i = 0; i < connection.numberOfConnections; i++) {
                plugSelections.push('Left')
              }
            } else if (this.selectedFloorItemRightPowerStrip.id === connection.powerStripId) {
              for (let i = 0; i < connection.numberOfConnections; i++) {
                plugSelections.push('Right')
              }
            }
          })
          this.powerPlugSelectionArray = [...plugSelections]
          this.$nextTick(() => { document.querySelectorAll('.v-input__slot').forEach(element => { this.addPowerStripHighlight(element) }) })
        } else {
          this.showNoModelPowerInformationDialog = true
        }
      },
      updatePowerSetup () {
        this.updatingPowerStripToRackConnections = true
        const leftCount = this.powerPlugSelectionArray.filter(selection => selection === 'Left').length
        const rightCount = this.powerPlugSelectionArray.filter(selection => selection === 'Right').length
        ewcApi.updatePowerStripToRackItemEwc(this.selectedRackItem.equipmentData.id, this.selectedFloor.id, [
          { powerStripId: this.selectedFloorItemLeftPowerStrip.id, numberOfConnections: leftCount },
          { powerStripId: this.selectedFloorItemRightPowerStrip.id, numberOfConnections: rightCount },
        ])
          .then(response => {
            console.log(response)

            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageUpdatePowerStripConnectionsSuccess
            this.showUpdatePowerSetupAlert = false
            this.showRackItemPowerSetupDialog = false
            this.powerPlugs = []
            this.powerPlugSelectionArray = []

            this.selectedFloorItem = null
            this.selectedRackItem = null
            this.localBus.$emit('clearSelectedFloorItem')

            this.getPowerMap()
          })
          .catch(error => {
            console.log(error)
            this.showUpdatePowerSetupAlert = true
            this.updatePowerSetupAlertMessage = this.alertMessageGeneral
            common.handleBadCall(error, this.$router)
          })
          .finally(() => { this.updatingPowerStripToRackConnections = false })
      },

      openModelSetupDialog () {
        this.showRackItemModelSetupDialog = true
        const modelPowerInformationIndex = binarySearchArrayOfObjects(this.modelPowerInformation, 'equipmentModelId', 0, this.modelPowerInformation.length - 1, this.selectedRackItem.equipmentModel.id)
        if (modelPowerInformationIndex !== -1) {
          this.stateRackItemPowerInputs = this.modelPowerInformation[modelPowerInformationIndex].powerInputs
          this.stateRackItemPowerDraw = this.modelPowerInformation[modelPowerInformationIndex].load
        }
      },
      updateModelSetup () {
        this.updatingModelSetup = true
        ewcApi.updateModelPowerInformationEwc({
          equipmentModelId: this.selectedRackItem.equipmentModel.id,
          powerInputs: this.stateRackItemPowerInputs,
          load: this.stateRackItemPowerDraw,
        })
          .then(response => {
            console.log(response)

            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageUpdateModelPowerInformationSuccess
            this.showUpdateModelSetupAlert = false
            this.showRackItemModelSetupDialog = false
            this.stateRackItemPowerInputs = -1
            this.stateRackItemPowerDraw = ''

            this.selectedFloorItem = null
            this.selectedRackItem = null
            this.localBus.$emit('clearSelectedFloorItem')

            this.getPowerMap()
          })
          .catch(error => {
            console.log(error)
            this.showUpdateModelSetupAlert = true
            this.updateModelSetupAlertMessage = this.alertMessageGeneral
            common.handleBadCall(error, this.$router)
          })
          .finally(() => { this.updatingModelSetup = false })
      },

      addHighlight (event) {
        this.$nextTick(() => {
          document.querySelectorAll('.v-list-item').forEach(element => { this.addPowerStripHighlight(element) })
        })
      },
      addPowerStripHighlight (element) {
        element.addEventListener('mouseover', e => {
          if (e.target.innerText === 'Left') {
            this.leftPowerStripHighlighted = true
          } else if (e.target.innerText === 'Right') {
            this.rightPowerStripHighlighted = true
          }
        })
        element.addEventListener('mouseout', e => {
          this.leftPowerStripHighlighted = false
          this.rightPowerStripHighlighted = false
        })
      },
    },
  }
</script>

<style >
</style>
